import React, { useState, useEffect } from 'react';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

function App() {  
  const [countdown, setCountdown] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [audioIndex, setAudioIndex] = useState(9);
  const [buttonClicked, setbuttonClicked] = useState(false);

  const audioFiles = ['audioA.mp3', 'audioB.mp3', 'audioC.mp3'];

  useEffect(() => { // 播放音檔
    const audio = new Audio(audioFiles[audioIndex]);
    audio.play();
  },[audioIndex])


  useEffect(() => { // 每秒確認播放與倒數

     setInterval(() => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      setCurrentTime(new Date());
      // 檢查是否在指定時間範圍內（07:00:00 至 23:59:59）
      if (hours >= 7 && hours <= 23) {
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        const remainder = totalSeconds % 300; // 計算當前週期的剩餘秒數
        setCountdown(300-remainder);


        // 根據週期中的不同時間點播放不同的音檔



        switch (remainder) {
          case 240: 
          setAudioIndex(0); // A音檔
            break;
          case 270: 
          setAudioIndex(1); // B音檔
            break;
          case 285:
          setAudioIndex(2); // C音檔
            break;
          default:
            break;
        }


      }
    }, 1000); // 每秒檢查一次

  }, []);


  const timeStyle = {
    fontSize: '10rem', // Increase font size
    color: '#333', // Dark gray color
    textAlign: 'center', // Center align the text
  };
  const timeStyle2 = {
    fontSize: '3rem', // Increase font size
    color: '#333', // Dark gray color
    textAlign: 'center', // Center align the text
  };

  return (
  <div className="container">
      <div className="time" style={timeStyle}>
      {currentTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit', hour12: false})}
      </div>
      {countdown > 0 && (
        <div className="countdown"  style={timeStyle2}>
          <button onClick={()=>{setbuttonClicked(true)}} disabled={buttonClicked} style={timeStyle2}>
            {buttonClicked ? "🔥Bingo倒數" : "請啟用bingo"}
          </button> {countdown} 秒 
        </div>

        
      )}
    </div>
  );

}



export default App